import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import AOS from "aos"; // Import AOS JS
import "aos/dist/aos.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "jquery/dist/jquery.min.js";
import "bootstrap/dist/js/bootstrap.bundle.js";
import "bootstrap-icons/font/bootstrap-icons.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { SearchPatientDataProvider } from "./subadmin/context/SearchPatientData";
import { SearchDoctorDataProvider } from "./subadmin/context/SearchDoctorData";
import { SubAdminProvider } from "./subadmin/context/SubAdminContext";

AOS.init();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <SubAdminProvider>
    <SearchPatientDataProvider>
    <SearchDoctorDataProvider>
      <App />
      </SearchDoctorDataProvider>
      
      </SearchPatientDataProvider>
    </SubAdminProvider>
    <ToastContainer />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
